const navbarScroll = () => {
  let navbar = document.getElementById("navbar");
  window.onscroll = function() {
    "use strict";
    if (document.body.scrollTop >= 90 || document.documentElement.scrollTop >= 90) {
      navbar.style.backgroundColor = "rgba(255, 255, 255, 0.9)"; // 1 for opacity none
    } else {
      navbar.style.backgroundColor = "transparent";
    }
  };
};
export { navbarScroll };
