const millesimes = () => {
  if(document.getElementById('chateau-grand-corbin-manuel') ||
     document.getElementById('chateau-haut-cadet') ||
     document.getElementById('chateau-la-creation')) {

    let selectors = [...document.querySelectorAll('.millesime-selector')];
    let dates = [...document.querySelectorAll('.millesime-date')];
    let packshots = [...document.querySelectorAll('.millesime-packshot')];
    let citations = [...document.querySelectorAll('.millesime-citation')];
    let authors = [...document.querySelectorAll('.millesime-author')];
    let technicals = [...document.querySelectorAll('.millesime-technical')];

    function toggleMillesime(selector) {
      let currentId = (selector.id).substr(selector.id.length - 4)
      let currentSelector = document.getElementById(`millesime-selector-${currentId}`);
      let currentDate = document.getElementById(`millesime-date-${currentId}`);
      let currentPackshot = document.getElementById(`millesime-packshot-${currentId}`);
      let currentCitation = document.getElementById(`millesime-citation-${currentId}`);
      let currentAuthor = document.getElementById(`millesime-author-${currentId}`);
      let currentTechnical = document.getElementById(`millesime-technical-${currentId}`);

      function removeSelected(element) {
        element.classList.remove('selected')
      };

    // toggle millesime-selectors
      selectors.forEach( element => removeSelected(element));
      currentSelector.classList.add('selected');
    // toggle millesime-dates
      dates.forEach( element => removeSelected(element));
      currentDate.classList.add('selected');
    // toggle millesime-packshots
      packshots.forEach( element => removeSelected(element));
      currentPackshot.classList.add('selected');
    // toggle millesime-citations
      citations.forEach( element => removeSelected(element));
      currentCitation.classList.add('selected');
    // toggle millesime-authors
      authors.forEach( element => removeSelected(element));
      currentAuthor.classList.add('selected');
    // toggle millesime-technicals
      technicals.forEach( element => removeSelected(element));
      currentTechnical.classList.add('selected');
    };

    function listenSelectors () {
      selectors.forEach(function (selector) {
        selector.addEventListener('click', event => {
          toggleMillesime(selector);
        });
      });
    };
    listenSelectors();

    if (window.innerWidth <= 767) {
      let leftScroller = document.getElementById('xs-selector-left');
      let rightScroller = document.getElementById('xs-selector-right');
      let selectorsBox = document.querySelector('.millesimes-selector-content');
      let scrollStep = window.innerWidth - 90; // 3 years
      // let scrollStep = ((window.innerWidth - 225)/6 * 4) + 90; // 2 years

      function listenLeftScroller() {
        leftScroller.addEventListener('click', event => {
          selectorsBox.scrollBy({ left: -scrollStep, behavior: 'smooth'});
        });
      };

      function listenRightScroller() {
        rightScroller.addEventListener('click', event => {
          selectorsBox.scrollBy({ left: scrollStep, behavior: 'smooth'});
        });
      };

      listenLeftScroller();
      listenRightScroller();
    };

  };
};
export { millesimes };
