const toggleMenu = () => {
  let menu = document.getElementById('menu')
  let menuOpener = document.getElementById('menu-burger');
  let menuCloser = document.getElementById('menu-closer');
  let body = document.body;

  function openMenu(){
    menu.style.display = 'flex';
    body.style.overflow = 'hidden';
  };
  function listenMenuOpener() {
    menuOpener.addEventListener('click', event => {
      openMenu();
    });
  };
  listenMenuOpener();

  function closeMenu(){
    menu.style.display = 'none';
    body.style.overflow = 'auto';
  };
  function listenMenuCloser() {
    menuCloser.addEventListener('click', event => {
      closeMenu()
    });
  };
  listenMenuCloser();

};
export { toggleMenu };
