const scroller = () => {
  let scrollerItems = [...document.querySelectorAll('.scroller-item')];

  function scrollDiv(scroller){
    let separator = scroller.parentNode;
    if (separator.classList.contains('separator-center')) {
        let scrollHeight = separator.offsetTop + 51;
        window.scroll({top: scrollHeight, behavior: 'smooth'});
    } else {
        let scrollHeight = separator.offsetTop + 30;
        window.scroll({top: scrollHeight, behavior: 'smooth'});
    };
  };

  function listenScroller() {
    scrollerItems.forEach(function(scroller) {
      scroller.addEventListener('click', event => scrollDiv(scroller));
    });
  };
  listenScroller();
};

export { scroller };
