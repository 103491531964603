// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// External imports
import 'bootstrap';

// Internal imports
import { toggleMenu } from '../components/toggle_menu';
import { navbarScroll } from '../components/navbar';
import { scroller } from '../components/scroller';
import { homeAutoScroll } from '../components/home_auto_scroll';
import { millesimes } from '../components/millesimes';
import { techSelector} from '../components/technical_selector';

document.addEventListener('turbolinks:load', () => {
  toggleMenu();
  navbarScroll();
  scroller();
  homeAutoScroll();
  millesimes ();
  techSelector ();
});

