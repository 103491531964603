const homeAutoScroll = () => {
  if (document.getElementById("homepage")) {
    function Scrolldown() {
      setTimeout(function(){window.scroll(0, innerHeight);},3000);
    }
    Scrolldown();
  };
};

export { homeAutoScroll };
