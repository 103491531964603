const techSelector = () => {
  if (document.getElementById('pro')) {
    let techSelectors = document.querySelectorAll('.pro-tech-selector-btn');
    let proSelectors = document.querySelectorAll('.dossier-selector-btn');


    function reverseSelector(selector) {
      let icon = selector.getElementsByTagName("img")[0];
      icon.classList.toggle('rotate-selector');
    };

    function listenTechSelectors() {
      techSelectors.forEach(function(selector) {
        selector.addEventListener('click', event => reverseSelector(selector));
      });
    };

    function listenProSelectors() {
      proSelectors.forEach(function(selector) {
        selector.addEventListener('click', event => reverseSelector(selector));
      });
    };

    listenTechSelectors();
    listenProSelectors();

  };
};

export { techSelector };
